import React from "react";

function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        // stroke="#000"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.75 8c0 5.25-6.75 9.75-6.75 9.75S2.25 13.25 2.25 8a6.75 6.75 0 0113.5 0z"
      ></path>
      <path
        // stroke="#000"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 10.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
      ></path>
    </svg>
  );
}

export default LocationIcon;
