import dashboardAction from "./action";

const initState = {
  show: false,
  message: "",
  type: "",
  isFetching: false,
  notification: []
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case dashboardAction.NOTIFICATION:
      return {
        ...state,
        notification: action.notification
      };

    default:
      return state;
  }
}
