import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AuthActions from "redux/auth/actions";
import { compose } from "redux";
import "./intro.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import '../../../assets/scss/app.scss'
// import '../../../assets/css/custom.css'
// import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const { success, error, updateCredits } = AuthActions;

const Home = props => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    dotsClass: "slick-dots custom-top-intro",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true,
    slidesToScroll: 1,
    waitForAnimate: false
  };

  return (
    <>
      <div className="intro-container">
        <section className="brand">
          <img src="/logo/logo.png" alt="" />
        </section>
        <section className="intro-slider">
          <Slider {...settings}>
            <div className="slides">
              <img src="/intro1.jpg" alt="Slide 1" />
              <div className="content">
                <h5>Meet Your Event Tribe</h5>
                <p>
                  Join a vibrant community of event-goers and connect with
                  people who share your interests.
                </p>
              </div>
            </div>
            <div className="slides">
              <img src="/intro2.jpg" alt="Slide 1" />
              <div className="content">
                <h5>Your Event, Your Way</h5>
                <p>
                  Get personalized recommendations, exclusive content, and
                  special offers.
                </p>
              </div>
            </div>
            <div className="slides">
              <img src="/intro3.jpg" alt="Slide 1" />
              <div className="content">
                <h5>Keep the Party Going</h5>
                <p>
                  Stay connected with new friends even after the event ends.
                </p>
              </div>
            </div>
          </Slider>
        </section>
        <section className="button-container">
          <button onClick={() => navigate("/register")}>
            Create an account
          </button>
          <button
            style={{ backgroundColor: "#7f7f7f" }}
            onClick={() => navigate("/login")}
          >
            Login
          </button>
        </section>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.accessToken,
    userInfo: state.auth.user,
    credits: state.auth.credits
  };
};

export default compose(
  connect(mapStateToProps, { success, error, updateCredits })
)(Home);
