// import React, { memo } from "react";
// import { Link } from "react-router-dom";
// import './chatHeader.css';

// const GroupChatHeader = ({ GroupDetails }) => {
//   console.log(GroupDetails, "GroupDetails");
//   return (
//     <>
//       <header
//         className="chat-header-container"
//         style={{
//           // backgroundColor: "#f7fdff",
//           // borderTop:"2px solid black",
//           // borderBottom:"2px solid black",
//           // position:"relative !important",
//           // height:"12%",
//           // display: "flex ",
//           // alignItems: "center",
//           // boxShadow: "#706d6c 0px 54px 55px, #706d6c 0px -12px 30px, #706d6c 0px 4px 6px, #706d6c 0px 12px 13px, #706d6c 0px -3px 5px",
//           // padding: "0 1rem"
//         }}
//       >
//         <div
//           // className="container-fluid"
//           className="group-chat-info"
//           style={{
//             // display: "flex",
//             // alignItems: "center"
//           }}
//           >
//           <Link
//           className="link-container"
//             to={`/userlist/${GroupDetails._id}`}
//             style={{
//               // display: "flex",
//               // alignItems: "center",
//               // textDecoration: "none",
//               // color: "inherit"
//             }}
//             >
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 marginRight: "1rem"
//               }}
//             >
//               <img
//                 src={GroupDetails.image}
//                 alt="Group"
//                 style={{
//                   width: "50px",
//                   height: "50px",
//                   borderRadius: "50%",
//                   objectFit: "cover",
//                   border: "2px solid black"
//                 }}
//               />
//             </div>
//             <div>
//               <p
//                 className="navbar-brand"
//                 style={{
//                   margin: "0",
//                   fontSize: "1.2rem",
//                   fontWeight: "bold"
//                 }}
//               >
//                 {GroupDetails.name}
//               </p>
//               <span
//                 style={{
//                   display: "block",
//                   fontSize: "0.9rem",
//                   color: "#555"
//                 }}
//               >
//                 {GroupDetails.userCount} Users
//               </span>
//             </div>
//           </Link>
//         </div>
//       </header>
//     </>
//   );
// };

// export default memo(GroupChatHeader);

import React, { memo } from "react";
import { Link } from "react-router-dom";
import "./chatHeader.css";
import BackIcon from "./BackIcon";
import GroupMembersIcon from "./GroupMembersIcon";

const GroupChatHeader = ({ GroupDetails }) => {
  console.log(GroupDetails, "GroupDetails");
  return (
    <>
      <header className="chat-header-container">
        <div className="group-chat-info">
          <Link to="/">
            <BackIcon />
          </Link>
          <Link className="link-container" to={`/userlist/${GroupDetails._id}`}>
            <div className="image-container">
              <img src={GroupDetails?.image?.url} alt="Group" />
            </div>
            <div className="group-info-text">
              <p className="group-name">{GroupDetails.name}</p>
              <span className="group-user-count">
                {GroupDetails.userCount} members
              </span>
            </div>
          </Link>
        </div>
        <div className="group-members-icon-container">
          <Link to={`/userlist/${GroupDetails._id}`}>
            <GroupMembersIcon />
          </Link>
        </div>
      </header>
    </>
  );
};

export default memo(GroupChatHeader);
