// import React, { useEffect, useState } from "react";
// import { compose } from "redux";
// import { connect } from "react-redux";
// import AuthActions from "redux/auth/actions";
// import "./adminpanel.css";
// import { useNavigate } from "react-router-dom";
// import { createCategory, fetchAllCategories } from "services/adminServices";

// const { success, error } = AuthActions;

// const AdminLocation = ({ token, role }) => {
//   const navigate = useNavigate();
//   const [allCategories, setAllCategories] = useState([]);
//   const [formValues, setFormValues] = useState({
//     name: "",
//     importance: 0,
//   });

//   useEffect(() => {
//     if (role !== "admin") {
//       navigate("/");
//       return;
//     }
//     const fetchCategories = async () => {
//       const data = await fetchAllCategories(token);
//       if (data.success) {
//         setAllCategories(data.data);
//         console.log(data.data);
//       } else {
//         console.error(data.message);
//       }
//     };

//     fetchCategories();
//   }, [token, role, navigate]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Adding category");
//     try{
//       const data = createCategory({name: formValues.name, importance: formValues.importance});
//       if(data.success){
//         setAllCategories([...allCategories, data.data]);
//         setFormValues({ name: "", importance: 0 });
//         success("Category added successfully");
//       }
//     }catch(err){
//       console.error(err);
//       error("Failed to add category");
//     }
//   };

//   return (
//     <div className="admin-panel-container">
//       <div className="event-form-container">
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             name="name"
//             value={formValues.name}
//             onChange={handleInputChange}
//             placeholder="Event Name"
//             required
//           />
//           <label>
//             Importance
//             <input
//               type="number"
//               name="importance"
//               value={formValues.importance}
//               onChange={handleInputChange}
//               min="0"
//             />
//           </label>
//           <button type="submit">Submit</button>
//         </form>
//       </div>
//       <div className="categories-container">
//         {allCategories.map((category) => (
//           <div key={category._id} className="category-card">
//             <div className="category-info">
//               <h3>{category.name}</h3>
//               <p>Importance: {category.importance}</p>
//             </div>
//             <div className="btn-container">
//               <button>Edit</button>
//               <button>Hide</button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   token: state.auth.user.token,
//   role: state.auth.user.role,
// });

// export default compose(connect(mapStateToProps, {success,error}))(AdminLocation);

// import React, { useEffect, useState } from "react";
// import { compose } from "redux";
// import { connect } from "react-redux";
// import AuthActions from "redux/auth/actions";
// import "./adminpanel.css";
// import { useNavigate } from "react-router-dom";
// import { createCategory, fetchAllCategories, updateCategory } from "services/adminServices";

// const { success, error } = AuthActions;

// const AdminLocation = ({ token, role }) => {
//   const navigate = useNavigate();
//   const [allCategories, setAllCategories] = useState([]);
//   const [formValues, setFormValues] = useState({ name: "", importance: 0 });
//   const [editingCategory, setEditingCategory] = useState(null);

//   useEffect(() => {
//     if (role !== "admin") {
//       navigate("/");
//       return;
//     }
//     const fetchCategories = async () => {
//       const data = await fetchAllCategories(token);
//       if (data.success) {
//         setAllCategories(data.data);
//         console.log(data.data)
//       } else {
//         console.error(data.message);
//       }
//     };
//     fetchCategories();
//   }, [token, role, navigate]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues(prev => ({ ...prev, [name]: value }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       if (editingCategory) {
//         const data = await updateCategory(formValues);
//         if (data.success) {
//           setAllCategories(prev => prev.map(cat => cat?._id === data?.data?._id ? data?.data : cat));
//           setEditingCategory(null);
//           success("Category updated successfully");
//         }
//       } else {
//         const data = await createCategory(formValues);
//         if (data.success) {
//           setAllCategories(prev => [...prev, data?.data]);
//           success("Category added successfully");
//         }
//       }
//       setFormValues({ name: "", importance: 0 });
//     } catch (err) {
//       console.error(err);
//       error("Operation failed");
//     }
//   };

//   const handleEdit = (category) => {
//     setEditingCategory(category);
//     setFormValues({ name: category.name, importance: category.importance });
//   };

//   return (
//     <div className="admin-panel-container">
//       <div className="event-form-container">
//         <form onSubmit={handleSubmit}>
//           <input
//             type="text"
//             name="name"
//             value={formValues.name}
//             onChange={handleInputChange}
//             placeholder="Category Name"
//             required
//           />
//           <label>
//             Importance
//             <input
//               type="number"
//               name="importance"
//               value={formValues.importance}
//               onChange={handleInputChange}
//               min="0"
//             />
//           </label>
//           <button type="submit">{editingCategory ? "Update" : "Submit"}</button>
//         </form>
//       </div>
//       <div className="categories-container">
//         {allCategories.map(category => (
//           <div key={category._id} className="category-card">
//             <div className="category-info">
//               <h3>{category.name}</h3>
//               <p>Importance: {category.importance}</p>
//             </div>
//             <div className="btn-container">
//               <button onClick={() => handleEdit(category)}>Edit</button>
//               <button>Hide</button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   token: state.auth.user.token,
//   role: state.auth.user.role,
// });

// export default compose(connect(mapStateToProps, { success, error }))(AdminLocation);

import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import "./adminpanel.css";
import { Link, useNavigate } from "react-router-dom";
import {
  createCategory,
  fetchAllCategories,
  updateCategory
} from "services/adminServices";

const { success, error } = AuthActions;

const AdminLocation = ({ token, role }) => {
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState([]);
  const [formValues, setFormValues] = useState({ name: "", importance: 0 });
  const [editingCategory, setEditingCategory] = useState(null);

  useEffect(() => {
    if (role !== "admin") {
      navigate("/");
      return;
    }
    const fetchCategories = async () => {
      const data = await fetchAllCategories(token);
      if (data.success) {
        setAllCategories(data.data || []);
      } else {
        console.error(data.message);
      }
    };
    fetchCategories();
  }, [token, role, navigate]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (editingCategory) {
        const data = await updateCategory(formValues, editingCategory);
        if (data.success) {
          setAllCategories(
            prev =>
              prev.map(cat => (cat?._id === data.data?._id ? data.data : cat)) // Safeguard against undefined
          );
          setEditingCategory(null);
          success("Category updated successfully");
        }
      } else {
        const data = await createCategory(formValues);
        if (data.success) {
          setAllCategories(prev => [...prev, data.data]);
          success("Category added successfully");
        }
      }
      setFormValues({ name: "", importance: 0 });
    } catch (err) {
      console.error(err);
      error("Operation failed");
    }
  };

  const handleEdit = category => {
    if (category) {
      setEditingCategory(category);
      setFormValues({ name: category.name, importance: category.importance });
    }
  };

  return (
    <div className="admin-panel-container">
      <Link to="/admin">Admin</Link>
      <div className="event-form-container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            placeholder="Category Name"
            required
          />
          <label>
            Importance
            <input
              type="number"
              name="importance"
              value={formValues.importance}
              onChange={handleInputChange}
              min="0"
            />
          </label>
          <button type="submit">{editingCategory ? "Update" : "Submit"}</button>
        </form>
      </div>
      <div className="categories-container">
        {allCategories.map(category =>
          category ? (
            <div key={category._id} className="category-card">
              <div className="category-info">
                <h3>{category.name}</h3>
                <p>Importance: {category.importance}</p>
              </div>
              <div className="btn-container">
                <button onClick={() => handleEdit(category)}>Edit</button>
                <button>Hide</button>
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.user.token,
  role: state.auth.user.role
});

export default compose(connect(mapStateToProps, { success, error }))(
  AdminLocation
);
