import Loader from "components/Loader";
import ChatViewComponent from "components/chats/chatViewComponent";
import GroupChatHeader from "components/chats/groupChatHeader";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import {
  addUserToGroup,
  getRecipientDetails
} from "services/dashboardServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const { success, error, updateCredits } = AuthActions;

var socket;

const GroupChat = props => {
  console.log(props);
  const { userInfo, isLogin } = props;

  console.log("user id : ", userInfo);
  console.log(isLogin);
  const { id } = useParams();
  console.log("groupd ID", id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eventDetail, setEventDetail] = useState();

  const getEventData = async () => {
    const data = await getRecipientDetails(userInfo.token, id);
    console.log(data);
    setEventDetail(data.data);
  };

  useEffect(() => {
    getEventData();
    // addUser();
  }, [id]);

  // new
  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [id]);

  return (
    <div
      style={{
        // border:"2px solid pink",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // height:"95vh",
        height: "92vh"
      }}
    >
      {/* {eventDetail ? (
        <>
          <GroupChatHeader GroupDetails={eventDetail} />

          <ChatViewComponent user={userInfo} groupId={id} isPersonal={true} />
        </>
      ) : (
        <Loader />
      )} */}
      {eventDetail && (
        <>
          <GroupChatHeader GroupDetails={eventDetail} />
          <ChatViewComponent user={userInfo} groupId={id} isPersonal={true} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  // console.log(state.auth)
  return {
    // userInfo: state.auth.accessToken
    userInfo: state.auth.user,
    isLogin: state.auth.isLogin
  };
};

export default compose(
  connect(mapStateToProps, { success, error, updateCredits })
)(GroupChat);
