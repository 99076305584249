import { toast } from "react-toastify";

const dashboardAction = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  FETHING: "FETHING",
  NOTIFICATION: "NOTIFICATION",

  success: messages => {
    messages !== "" &&
      toast.success(messages, {
        position: "top-right",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    return {
      type: dashboardAction.SUCCESS,
      resType: "success",
      messages: messages,
      show: true,
      isFetching: false
    };
  },

  error: messages => {
    messages !== "" &&
      toast.error(messages, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    return {
      type: dashboardAction.ERROR,
      resType: "error",
      messages: messages,
      show: true,
      isFetching: false
    };
  },

  notificationdata: data => {
    return {
      type: dashboardAction.NOTIFICATION,
      notification: data
    };
  },

  fetching: () => ({
    type: dashboardAction.FETHING,
    isFetching: true
  })
};

export default dashboardAction;
