import authAction from "./actions";

const initState = {
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin") === "true"
    : false,
  accessToken: localStorage.getItem("accessToken"),
  user: {},
  credits: parseInt(localStorage.getItem("credits"), 10) || 0
};

export default function rootReducer(state = initState, action) {
  console.log(action.type, "the action set the token");

  switch (action.type) {
    case authAction.LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: action.accessToken,
        user: action.user
      };
    case authAction.SET_USER:
      return {
        ...state,
        user: action.user
      };
    case authAction.SET_PROFILE_PIC:
      return {
        ...state,
        user: {
          ...state.user,
          profileImage: action.payload.profileImage
        }
      };
    case authAction.UPDATE_CREDITS:
      console.log("action: ", action);
      return {
        ...state,
        credits: action.credits
      };
    case authAction.LOGOUT:
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: null,
        user: {},
        credits: 0
      };
    default:
      return state;
  }
}
