import servicesAction from "./action";

const initState = {
  show: false,
  message: "",
  resType: "",
  isFetching: false
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case servicesAction.SUCCESS:
      return {
        ...state,
        show: action.show,
        message: action.message,
        resType: action.resType,
        isFetching: action.isFetching
      };

    case servicesAction.ERROR:
      return {
        ...state,
        show: action.show,
        message: action.message,
        resType: action.resType,
        isFetching: action.isFetching
      };

    case servicesAction.FETHING:
      return {
        ...state,
        isFetching: action.isFetching
      };

    default:
      return state;
  }
}
