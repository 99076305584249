import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="27"
      fill="none"
      viewBox="0 0 25 27"
    >
      <path
        // stroke="#000"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.5 2.25l-9 7.875V22.5a2.4 2.4 0 00.586 1.591c.375.422.884.659 1.414.659h14c.53 0 1.04-.237 1.414-.659A2.4 2.4 0 0021.5 22.5V10.125l-9-7.875z"
      ></path>
      <path
        // stroke="#000"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.5 24.75V13.5h6v11.25"
      ></path>
    </svg>
  );
}

export default HomeIcon;
