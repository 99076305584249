import auth from "./auth/reducer";
import services from "./services/reducer";
import navigation from "./navigation/reducer";
import dashboardNotification from "./dashboard/reducer";
import home from './home/reducer'
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    services,
    home,
    dashboardNotification,
    navigation,
    router: routerReducer,

    ...asyncReducers
  });

export default createReducer;
