import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("This Field is required"),
    password: Yup.string()
      .required("Please enter new password")
      .min(
        8,
        "Password must be at least 8 characters"
      ),
      // .matches(
      //   /[a-z]/,
      //   "Password must be at least 8 characters it needs to have uppercase and lowercase letters"
      // )
      // .matches(
      //   /[A-Z]/,
      //   "Password must be at least 8 characters it needs to have uppercase and lowercase letters"
      // ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("This Field is required")
  }),

  mapPropsToValues: props => ({
    firstname: "",
    phone_number: "",
    email: "",
    file_image: "",
    password: "",
    confirm_password: ""
  }),
  validateOnMount: true,
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
