import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="36"
      height="37"
      fill="none"
      viewBox="0 0 36 37"
    >
      <path
        fill="url(#pattern0_2337_82)"
        d="M0.639 0.752H35.947V36.06H0.639z"
      ></path>
      <defs>
        <pattern
          id="pattern0_2337_82"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.01042)" xlinkHref="#image0_2337_82"></use>
        </pattern>
        <image
          id="image0_2337_82"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEfUlEQVR4nO2cTU8UQRCG310gwKoJHg0fxqjryQAqS0ATUINEhSiryy7+COJR9GDUO/o//PgL3jTeMYJeFaPxYjAS5dCmQ22ysB/TM9M9XbPWk1RCYNieemu6u7q6ZwFBEARBEARBEARBEARB4EcXgKMACgBmARQBlMmK9LsCXaOvFSxxCMA4gEUAS4a2SMHQ/ytEpAPAKIBKCOH3m+4dI/RZQsin/loM4ffbDIBeiYAZh2lMX7JsNwD0SRCCn/yiA/Frg9AjQWhMB4CrDsWvms6WZE5owGgC4lfttPSC+qGnkmAASjIU7WU8QfGrdk56wS5dIRdZNnuBrJixWzpY8mRDSBk5AHkA0wCu01NUop+n6W/6mjAUPAZgjIH/RvTSzZpMlPqa8wAOGH72rMcAzDDwP5ABinJY524D6Df4/KLHACww8L8lp2KmhxXqlq0oewxAmYH/LSNvIzevBDwJZaYBSMr/huQidrtW3bE3RUNQLkH/E1sY6WwnLZPweIL+N4y+i7JApUmKVmCWhibtfx15hw6fTMFCLJ+w/3VMO7yBqQbtdVoeb02tRG379r+OOYc3oFeMYDIMFRj5vweXT6P+7Gbl6HKC4uu2DjLyP7Eb0OlYM0YSDMAwQ/+9d8EOSgtdi6/byDL0n8Uk1EMb567av2mwIPI+CftOw/ocBcH0WIpv//0vRAB0A7hsedgxPY7CwX8naWHYzY8snV4oxcx2hgPGfJb+99KM7a0YVUMPbaCHCUSJHG6WaqbC/yM+y7EN6KTSgRb2ClUyq8fTF+h3Y3RNoxVuKv3P+9yQYAAL//sjdkcrW3IMYOG/zkrOhtiUnmiz4+DdXPzPUS7b6FjGFP3NybEMJvzv/guCIAgpJ0MZxWMArwGsA/gFQCVsus0PdA+PAJzBfyC8Poa+4UFsZWjrlL/re20rjgN4x0BgZWhvARxDm3ABwDcGoqqQ9gPAJaSciwD+MhBTRbQ/prtYHNFd+DsDEZWFnnACKSOTsjFfBdibtE3MZQaiKct2y6egWdromKwpQjWrBt4BsMlAMGXZvgRUQatFuUnSKuw2aFMGAcyHqIM/YCCWcmQrIXSYJ+0ik4n49QGvGAilHNmLCHqMRp0/on53w3sGQilHthZRE33sMhSDERvS9pWBUMqRbcbQRb9zZkQ25Ji/37YZCKUc2XYMXeZNJ+ahGI20ewB+J/HVCJMxG2nHFFQZpqJBpjfrA4l7PFsmYfh9Q+YlgydVObLnaXhD5j4DoZQju5eGN2TatRTxOaYuxkNQ3ElY2zMGginLtmpBl4kk0tBqL/jEQDRlyTbIp7i6GNWGspZeVFsG8JOBeCqmbQG4a0EP44VY3FJEremjJzsMRFQRbYeOrNjQwrgUYfuLVB+mtCdsAXiSwHvJTclYfHlaD0cfGYiqQoz5y5Z8H4m7nTlgaU7Qk9hTWs4rxqnmqiXh56IMO0FbkhMGW5ImtkKbG2sUEB8FvG1qe43uJe4iq7olOUFzqLUtSUEQBEEQBEEQBEEQBEEQBEEQkGr+AVZpnnRpE5EwAAAAAElFTkSuQmCC"
        ></image>
      </defs>
    </svg>
  );
}

export default Logo;