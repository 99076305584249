// SkeletonCard.js
import React from "react";
import "./SkeletonEventCard.css";

const SkeletonCard = () => {
  return (
    <div className="skeleton-card">
      <div className="skeleton-image"></div>
      <div className="skeleton-text skeleton-title"></div>
      <div className="skeleton-text skeleton-description"></div>
      <div className="skeleton-avatar-stack"></div>
      <div className="skeleton-button"></div>
    </div>
  );
};

export default SkeletonCard;
