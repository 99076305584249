import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    newpassword: Yup.string()
      .required("Please enter new password")
      .min(
        8,
        "Password must be at least 8 characters it needs to have uppercase and lowercase letters"
      )
      .matches(
        /[a-z]/,
        "Password must be at least 8 characters it needs to have uppercase and lowercase letters"
      )
      .matches(
        /[A-Z]/,
        "Password must be at least 8 characters it needs to have uppercase and lowercase letters"
      ),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), null], "The new passwords do not match")
      .required("Please enter confirm password")
  }),
  mapPropsToValues: props => ({
    newpassword: "",
    confirmpassword: ""
  }),
  validateOnMount: true,
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
