// import React, { useState, useEffect } from 'react';
// import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Container, Grid, Card, Typography, Divider, Box, ListItemButton } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import PersonalChat from './personalChat';
// import { connect } from "react-redux";
// import AuthActions from "redux/auth/actions";
// import { compose } from "redux";
// import { getAllMyChats } from 'services/dashboardServices';
// import { Link, useParams } from 'react-router-dom';
// import GroupChat from './groupChat';

// const { success, error, updateCredits } = AuthActions;

// function MyChats(props) {
//   const { userInfo } = props;
//   const [events, setEvents] = useState([]);
//   const [dms, setDms] = useState([]);
//   const [selectedChat, setSelectedChat] = useState(null);

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const { chatId } = useParams();
//   console.log(chatId)

//   const handleChatSelect = (chat) => {
//     setSelectedChat(chat);
//     console.log(chat)
//     console.log(chatId)
//   };

//   useEffect(() => {
//     const fetchChats = async () => {
//       try {
//         const data = await getAllMyChats(userInfo.token);
//         setEvents(data.events);
//         setDms(data.dms);

//         if (chatId) {
//           const selected = data.events.find(event => event.eventId._id === chatId) || data.dms.find(dm => dm._id === chatId);
//           setSelectedChat(selected);
//         }
//       } catch (error) {
//         console.error('Error fetching chats:', error);
//       }
//     };

//     fetchChats();
//   }, [userInfo.token]);

//   return (
//     <Container style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
//       <Grid container style={{ flex: 1, overflow: 'hidden' }}>
//         {(!isMobile || !selectedChat) && (
//           <Grid item xs={12} sm={4} style={{ borderRight: '1px solid #ccc', overflowY: 'auto' }}>
//             <Card style={{ height: '100%' }}>
//               <Typography variant="h6" style={{ padding: '16px' }}>
//                 Chats
//               </Typography>
//               <Divider />
//               <Typography variant="subtitle1" style={{ padding: '8px' }}>
//                 Events
//               </Typography>
//               <List>
//                 {events.map((event) => (
//                   <ListItemButton
//                     component={Link}
//                     to={`/my_chats/chat/${event.eventId._id}`}
//                     key={event.eventId._id}
//                     onClick={() => handleChatSelect(event)}
//                     selected={selectedChat && selectedChat.eventId?._id === event.eventId?._id}
//                   >
//                     <ListItemAvatar>
//                       <Avatar src={event.eventId.image} />
//                     </ListItemAvatar>
//                     <ListItemText primary={event.eventId.name} />
//                   </ListItemButton>
//                 ))}
//               </List>
//               <Divider />
//               <Typography variant="subtitle1" style={{ padding: '8px' }}>
//                 DMs
//               </Typography>
//               <List>
//                 {dms.map((dm) => (
//                   <ListItemButton
//                     component={Link}
//                     to={`/my_chats/recipient/${dm._id}`}
//                     key={dm._id}
//                     onClick={() => handleChatSelect(dm)}
//                     selected={selectedChat && selectedChat._id === dm._id}
//                   >
//                     <ListItemAvatar>
//                       <Avatar src={dm.profileImage} />
//                     </ListItemAvatar>
//                     <ListItemText primary={dm.name} />
//                   </ListItemButton>
//                 ))}
//               </List>
//             </Card>
//           </Grid>
//         )}
//         {(!isMobile || selectedChat) && (
//           <Grid item xs={12} sm={8} style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
//             {selectedChat ? (
//               <Card style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
//                 {/* <Typography variant="h6" style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
//                   {selectedChat.name}
//                 </Typography> */}
//                 {/* <Box style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
//                   <PersonalChat />
//                 {/* </Box> */}
//               </Card>
//             ) : (
//               <Box style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                 <Typography variant="h6">Select a chat to start messaging</Typography>
//               </Box>
//             )}
//           </Grid>
//         )}
//       </Grid>
//     </Container>
//   );
// }

// const mapStateToProps = (state) => {
//   return {
//     token: state.auth.accessToken,
//     userInfo: state.auth.user,
//   };
// };

// export default compose(connect(mapStateToProps, { success, error, updateCredits }))(MyChats);

import React, { useState, useEffect } from "react";
import {
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Container,
  Grid,
  Card,
  Typography,
  Divider,
  Box,
  ListItemButton
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PersonalChat from "./personalChat";
import GroupChat from "./groupChat";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { compose } from "redux";
import { getAllMyChats } from "services/dashboardServices";
import { Link, useParams } from "react-router-dom";

const { success, error, updateCredits } = AuthActions;

function MyChats(props) {
  const { userInfo } = props;
  const [events, setEvents] = useState([]);
  const [dms, setDms] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isEvent, setIsEvent] = useState(false); // New state to track if the selected chat is an event

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { chatId } = useParams();

  const handleChatSelect = (chat, isEvent) => {
    setSelectedChat(chat);
    setIsEvent(isEvent);
  };

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const data = await getAllMyChats(userInfo.token);
        setEvents(data.events);
        setDms(data.dms);

        if (chatId) {
          const selected =
            data.events.find(event => event.eventId._id === chatId) ||
            data.dms.find(dm => dm._id === chatId);
          setSelectedChat(selected);
          setIsEvent(!!data.events.find(event => event.eventId._id === chatId));
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchChats();
  }, [userInfo.token, chatId]);

  return (
    <Container
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Grid container style={{ flex: 1, overflow: "hidden" }}>
        {(!isMobile || !selectedChat) && (
          <Grid
            item
            xs={12}
            sm={4}
            style={{ borderRight: "1px solid #ccc", overflowY: "auto" }}
          >
            <Card style={{ height: "100%" }}>
              <Typography variant="h6" style={{ padding: "16px" }}>
                Chats
              </Typography>
              <Divider />
              <Typography variant="subtitle1" style={{ padding: "8px" }}>
                Events
              </Typography>
              <List>
                {events.map(event => (
                  <ListItemButton
                    component={Link}
                    to={`/my_chats/chat/${event.eventId._id}`}
                    key={event.eventId._id}
                    onClick={() => handleChatSelect(event, true)} // Mark as event
                    selected={
                      selectedChat &&
                      selectedChat.eventId?._id === event.eventId?._id
                    }
                  >
                    <ListItemAvatar>
                      <Avatar src={event.eventId.image} />
                    </ListItemAvatar>
                    <ListItemText primary={event.eventId.name} />
                  </ListItemButton>
                ))}
              </List>
              <Divider />
              <Typography variant="subtitle1" style={{ padding: "8px" }}>
                DMs
              </Typography>
              <List>
                {dms.map(dm => (
                  <ListItemButton
                    component={Link}
                    to={`/my_chats/recipient/${dm._id}`}
                    key={dm._id}
                    onClick={() => handleChatSelect(dm, false)} // Mark as DM
                    selected={selectedChat && selectedChat._id === dm._id}
                  >
                    <ListItemAvatar>
                      <Avatar src={dm.profileImage} />
                    </ListItemAvatar>
                    <ListItemText primary={dm.name} />
                  </ListItemButton>
                ))}
              </List>
            </Card>
          </Grid>
        )}
        {(!isMobile || selectedChat) && (
          <Grid
            item
            xs={12}
            sm={8}
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden"
            }}
          >
            {selectedChat ? (
              <Card
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                {isEvent ? <GroupChat /> : <PersonalChat />} // Render based on
                the type of chat
              </Card>
            ) : (
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography variant="h6">
                  Select a chat to start messaging
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    token: state.auth.accessToken,
    userInfo: state.auth.user
  };
};

export default compose(
  connect(mapStateToProps, { success, error, updateCredits })
)(MyChats);
